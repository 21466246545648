import React from "react";
import Product from "src/components/carte/product";
import { v4 as uuidv4 } from 'uuid';
import SubCategories from "src/components/carte/subcategories";

/**
 * Composant permettant d'afficher les catégories de la carte
 * @returns {JSX.Element}
 * @constructor
 */
export default class Categories extends React.Component {
  sortCategories(arrayCategories, arrayToOrder) {
    arrayCategories.sort(function(a, b) {
      let relativePathA = a.node.relativePath
      let relativePathB = b.node.relativePath
      let indexOrderA = arrayToOrder.findIndex(path => path.includes('content/' + relativePathA))
      let indexOrderB = arrayToOrder.findIndex(path => path.includes('content/' + relativePathB))

      if (indexOrderA === -1 && indexOrderB >= 0) {
        return 1
      }else if (indexOrderB === -1 && indexOrderA >= 0){
        return -1
      }else{
        return indexOrderA - indexOrderB
      }
    })

    return arrayCategories
  }

  render() {
    const { dataCategories, dataProducts, dataSettings, arrayOrderCategories } = this.props
    let mainCategories = dataCategories.filter(category => !category.node.childMarkdownRemark.frontmatter.is_sub_category);
    let mainCategoriesOrdered = this.sortCategories(mainCategories, arrayOrderCategories)

    return (
      mainCategoriesOrdered.map(({ node }) => {
        let category = {
          id: node.childMarkdownRemark.id,
          title: node.childMarkdownRemark.frontmatter.category_title,
          name: node.name,
          isSubCategory: node.childMarkdownRemark.frontmatter.is_sub_category,
          isDisplayQuantitiespricesColumn: node.childMarkdownRemark.frontmatter.is_display_quantitiesprices_column,
          relatedElements: node.childMarkdownRemark.frontmatter.related_elements
        }

        // Cas particuliers
        let classString = "category";
        if(category.title.includes("bloody du live")){
          category.title = "Les <span>bloody</span> du live !"
          classString += ' is-bloody'
        }

        // On affiche les catégorie de premier niveau (Pas les sous-catégorie)
        if(!node.childMarkdownRemark.frontmatter.mode_brouillon) {
          return (
            <div key={category.id} className={classString}>
              <h4 dangerouslySetInnerHTML={{__html: category.title}}/>
              {category.relatedElements.map(({list_beverages_selected, selected_elements, template}) => {
                let $returnTemplate;
                if (template === "liste-de-boissons") {
                  $returnTemplate = list_beverages_selected.map((beveragePathName, index) => {
                    return (
                      <Product key={uuidv4()} category={category} dataSettings={dataSettings}
                               productPathName={beveragePathName} dataProducts={dataProducts} productIndex={index}
                               isDisplayQuantitiespricesColumn={category.isDisplayQuantitiespricesColumn}/>
                    )
                  })
                } else if (template === "liste-de-plats") {
                  $returnTemplate = selected_elements.map((dishePathName, index) => {
                    return (
                      <Product key={uuidv4()} category={category} dataSettings={dataSettings}
                               productPathName={dishePathName} dataProducts={dataProducts} productIndex={index}
                               productType={"dishe"}/>
                    )
                  });
                } else { // On affiche la liste de sous catégorie de façon récursive si existe
                  $returnTemplate = <SubCategories
                    key={uuidv4()}
                    dataCategories={dataCategories}
                    selectedSubCategories={selected_elements}
                    dataProducts={dataProducts}/>
                }

                return ($returnTemplate)
              })}
            </div>
          )
        }else{
          return ("")
        }
      })
    )
  }
}
