import React from "react";
import Categories from "./categories";

/**
 * Composant permettant d'afficher la liste des plats
 * @returns {JSX.Element}
 * @constructor
 */
export default class ListDishes extends React.Component {
  render() {
    const { dataPlats, dataBoissons, dataCategories, dataSettings } = this.props
    const currentCategories = dataCategories.edges;
    const mainSettingsCarte = dataSettings.edges.find(setting => setting.node.name.includes("parametres-page-carte")).node
    const arrayOrderCategoriesDishes = mainSettingsCarte.childMarkdownRemark.frontmatter.order_display_categories_dishes

    let descDishesString = mainSettingsCarte.childMarkdownRemark.frontmatter.desc_dishes
    let beveragesAllowed = mainSettingsCarte.childMarkdownRemark.frontmatter.list_beverages_allowed

    return (
        <div id={"menu-list-dishes"}>
          {descDishesString !== "" ? (
            <div className={"desc-header"}><span>{descDishesString}</span></div>
            ) : ("")
          }
          <div className={"list-beverages-allowed"}>
            <h5>Boissons disponibles :</h5>
            <div>
              {
                beveragesAllowed.length > 1 ? (
                  beveragesAllowed.map((productPathName, index) => {
                    let beverageData = dataBoissons.edges.find(product => productPathName.includes(product.node.relativePath)).node;
                    let beverageNameNotFormatted = beverageData.childMarkdownRemark.frontmatter.titre_du_produit
                    beverageNameNotFormatted = beverageNameNotFormatted.toLowerCase();
                    beverageNameNotFormatted = beverageNameNotFormatted.charAt(0).toUpperCase() + beverageNameNotFormatted.slice(1)
                    let beverageName = (index === (beveragesAllowed.length-1)) ?
                      beverageNameNotFormatted  :
                      beverageNameNotFormatted + ', '

                    return(beverageName)
                  })
                ) : ("")
              }
            </div>
          </div>
          <Categories
            dataCategories={currentCategories}
            dataProducts={dataPlats}
            dataSettings={dataSettings}
            arrayOrderCategories={arrayOrderCategoriesDishes}/>
        </div>
    );
  }
}
