import React from "react";

/**
 * Composant permettant d'afficher les prix et quantité d'un produit
 * @returns {JSX.Element}
 * @constructor
 */
export default class ProductPricequantity extends React.Component {
  render() {
    const { priceQuantity } = this.props
    let singlePrice = (priceQuantity[0].prix === '') ? "-" : (Number.parseFloat(priceQuantity[0].prix.replace(",", ".")).toFixed(2) + '&nbsp;€')
    return(
      <div className={"product-pricequantity-container"}>
        {
          priceQuantity.length > 1 ? (
            priceQuantity.map((item, index) => {
              let quantityHtml = (item.quantite !== "") ? <div className={"quantity"}>{item.quantite}</div> : "";
              let price = (item.prix === '0') ? "-" : (Number.parseFloat(item.prix.replace(",", ".")).toFixed(2) + '&nbsp;€')
              return(
                <div key={index} className={"multiple"}>
                  {quantityHtml}
                  <div className={"price"} dangerouslySetInnerHTML={{__html: price}} />
                </div>
              )
            })
          ) : (
            <div className={"single"}>
              {(priceQuantity[0].quantite !== "") ? <div className={"quantity"}>{priceQuantity[0].quantite}</div> : ""}
              <div className={"price"} dangerouslySetInnerHTML={{__html: singlePrice}} />
            </div>
          )
        }
      </div>
    )
  }
}
