import * as React from "react"
import {graphql} from "gatsby"
import MainLayout from "src/components/layoutmain";
import LayoutCentered from "src/components/layoutcentered";
import Choice from "src/components/carte/choice";
import ListDishes from "src/components/carte/list-dishes";
import ListBeverages from "src/components/carte/list-beverages";

/**
 * Page carte
 * @returns {JSX.Element}
 * @constructor
 */
export default function Carte({ data }) {
    const pageProps = {
        title: "Le live bar - La carte",
        menuPageTitle: "Carte du live bar",
        description:
            "Vins, cocktails, bières, softs, spiritueux, chaud ou froid, à manger ou à boire, au live bar vous en avez pour tous les goûts.",
        className: "carte",
    };

    const carteData = data.allFile.group;
    const dataBoissons = carteData.find(element => element.fieldValue === "boissons");
    const dataCategoriesBeverages = carteData.find(element => element.fieldValue === "categories/boissons");
    const dataCategoriesDishes = carteData.find(element => element.fieldValue === "categories/plats");
    const dataPlats = carteData.find(element => element.fieldValue === "plats");
    const dataSettings = carteData.find(element => element.fieldValue === "settings");

    return (
        <MainLayout pageProps={pageProps}>
            <LayoutCentered>
                <Choice />
                <ListDishes
                  dataSettings={dataSettings}
                  dataCategories={dataCategoriesDishes}
                  dataPlats={dataPlats}
                  dataBoissons={dataBoissons}
                />
                <ListBeverages
                  dataSettings={dataSettings}
                  dataCategories={dataCategoriesBeverages}
                  dataBoissons={dataBoissons}/>
                <div>
                </div>
            </LayoutCentered>
        </MainLayout>
    )
}

export const query = graphql`
  query {
    allFile(filter: {sourceInstanceName: {eq: "forestry-content"}, relativePath: {regex: "/.*.md/"}}) {
        totalCount
        group(field: relativeDirectory) {
          fieldValue
          totalCount
          edges {
            node {
              childMarkdownRemark {
                id
                frontmatter {
                  # Paramètres produit (boisson, plat)
                  mode_brouillon
                  titre_du_produit
                  description_du_produit
                  prix_quantite {
                    prix
                    quantite
                  }
                  # Paramètres settings
                  price_dishe_beverage
                  price_dishe_beverage_coffee
                  list_beverages_allowed
                  order_display_categories_beverages
                  desc_dishes
                  order_display_categories_dishes
                  # Paramètres catégories
                  mode_brouillon
                  category_title
                  is_sub_category
                  is_display_quantitiesprices_column
                  related_elements {
                    template
                    list_beverages_selected
                    selected_elements
                  }
                }
              }
              relativePath
              name
              relativeDirectory
            }
          }
        }
    }
  }
`