import React from "react";
import ProductPricequantity from "./product-pricequantity";
import ProductPricequantityDishe from "./product-pricequantity-dishe";
import {v4 as uuidv4} from "uuid";

/**
 * Composant permettant d'afficher le contenu d'un produit
 * @returns {JSX.Element}
 * @constructor
 */
export default class Product extends React.Component {
  render() {
    const { category, dataSettings, dataProducts, productPathName, productIndex, isDisplayQuantitiespricesColumn, productType } = this.props

    let dataProduct = dataProducts.edges.find(product => productPathName.includes(product.node.relativePath));
    dataProduct = (typeof dataProduct !== "undefined") ? dataProduct.node : null;

    if(dataProduct !== null && !dataProduct.childMarkdownRemark.frontmatter.mode_brouillon){
      let showInColumn = (isDisplayQuantitiespricesColumn && productIndex === 0) ? isDisplayQuantitiespricesColumn : false;
      let classString = "product";
      if(showInColumn){
        classString += ' display-incolumn'
      }else{
        if(isDisplayQuantitiespricesColumn){
          classString += ' is-display-incolumn-active'
        }
      }
      let productTitle = dataProduct.childMarkdownRemark.frontmatter.titre_du_produit

      // Cas particuliers
      // Pour les vins rouge/rosé/blanc
      if(typeof category !== "undefined" && category.name === "vins") {
        let regExpRed = new RegExp("\\(Rouge\\)", 'gi');
        let regExpWhite = new RegExp("\\(Blanc\\)", 'gi');
        let regExpRose = new RegExp("\\(Rosé\\)|\\(Rose\\)", 'gi');

        if (regExpRed.test(productTitle)) {
          productTitle = productTitle.replace(regExpRed, '<span class="red">(Rouge)</span>');
        } else if (regExpWhite.test(productTitle)) {
          productTitle = productTitle.replace(regExpWhite, '<span class="white">(Blanc)</span>');
        } else if (regExpRose.test(productTitle)) {
          productTitle = productTitle.replace(regExpRose, '<span class="rose">(Rosé)</span>');
        }
      }

    // if(true){ // Test
      if(productType === "dishe" && category.name === "plats"){
        return (
          <div key={uuidv4()} className={"product product-dishe"}>
            <div className={"product-titledesc"}>
              <div className={"product-titledesc-title"}><div dangerouslySetInnerHTML={{ __html: productTitle }}/></div>
              <div className={"product-titledesc-desc"}>{dataProduct.childMarkdownRemark.frontmatter.description_du_produit}</div>
            </div>
            <ProductPricequantityDishe dataSettings={dataSettings} priceQuantity={dataProduct.childMarkdownRemark.frontmatter.prix_quantite} />
          </div>
        )
      }else{
        return (
          <div key={uuidv4()} className={classString}>
            <div className={"product-titledesc"}>
              <div className={"product-titledesc-title"}><div dangerouslySetInnerHTML={{ __html: productTitle }}/></div>
              <div className={"product-titledesc-desc"}>{dataProduct.childMarkdownRemark.frontmatter.description_du_produit}</div>
            </div>
            <ProductPricequantity priceQuantity={dataProduct.childMarkdownRemark.frontmatter.prix_quantite}/>
          </div>
        )
      }
    }else{
      return ("")
    }
  }
}
