import React from "react";

/**
 * Composant permettant d'afficher des boutons permettant de switcher entre les plats et boissons
 * @returns {JSX.Element}
 * @constructor
 */
export default class Choice extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeEat: false, activeDrink: true };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.getElementsByClassName("main")[0].classList.add('component-choice-enabled');
    document.getElementById("menu-list-beverages").classList.add('active')
  }

  handleClick(ev) {
    let button = ev.target;
    let $contentBeverages = document.getElementById("menu-list-beverages");
    let $contentDishes = document.getElementById("menu-list-dishes");

    // Change state when click on button and not enabled. Only one button can be enabled.
    if(!button.classList.contains('active')) {
      if (button.classList.contains('choice-eat')) {
        this.setState({activeEat: !this.state.active, activeDrink: false});
        $contentDishes.classList.add('active')
        $contentBeverages.classList.remove('active')
      } else if (button.classList.contains('choice-drink')) {
        this.setState({activeEat: false, activeDrink: !this.state.active});
        $contentDishes.classList.remove('active')
        $contentBeverages.classList.add('active')
      }
    }
  }

  render() {
    return (
      <div className={"component-choice"}>
        <button onClick={this.handleClick} className={this.state.activeEat ? "choice-eat active" : "choice-eat"}>Tu as les <br/>Crocs ?</button>
        <button onClick={this.handleClick} className={this.state.activeDrink ? "choice-drink active" : "choice-drink"}>Tu as <br/>Soif ?</button>
      </div>
    );
  }
}
