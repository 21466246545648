import React from "react";
import Product from "./product";
import {v4 as uuidv4} from "uuid";

/**
 * Composant permettant d'afficher les sous-catégories de la carte
 * @returns {JSX.Element}
 * @constructor
 */
export default class SubCategories extends React.Component {
  render() {
    const { selectedSubCategories, dataCategories, dataProducts  } = this.props
    // const currentCategories = dataCategories.find(category => selectedSubCategories.includes("content/"+category.node.relativePath));

    return (
      selectedSubCategories.map((categoryPathName) => {
        let currentDataCategory = dataCategories.find(category => categoryPathName.includes(category.node.relativePath));

        //La catégorie doit être accessible, les dépendances ne sont pas gérer lors de la suppression d'une catégorie!
        if(typeof currentDataCategory !== "undefined" && !currentDataCategory.node.childMarkdownRemark.frontmatter.mode_brouillon) {
          let category = {
            id: currentDataCategory.node.childMarkdownRemark.id,
            title: currentDataCategory.node.childMarkdownRemark.frontmatter.category_title,
            relatedElements: currentDataCategory.node.childMarkdownRemark.frontmatter.related_elements
          }

          return (
            <div key={category.id} className={"sub-category"}>
              <h5>{category.title}</h5>
              {category.relatedElements.map(({list_beverages_selected, template}) => {
                let $returnTemplate = '';
                if (template === "liste-de-boissons") {
                  $returnTemplate = list_beverages_selected.map((beveragePathName) => {
                    return (
                      <Product key={uuidv4()} productPathName={beveragePathName} dataProducts={dataProducts}/>
                    )
                  })
                }

                return ($returnTemplate)
              })}
            </div>
          )
        }else{
          return ("")
        }
      })
    )
  }
}
