import React from "react";
import Categories from "src/components/carte/categories";

/**
 * Composant permettant d'afficher la liste des boissons
 * @returns {JSX.Element}
 * @constructor
 */

export default class ListBeverages extends React.Component {
  render() {
    const { dataBoissons, dataCategories, dataSettings } = this.props
    const currentCategories = dataCategories.edges;
    const mainSettingsCarte = dataSettings.edges.find(setting => setting.node.name.includes("parametres-page-carte")).node
    const arrayOrderCategoriesBeverages = mainSettingsCarte.childMarkdownRemark.frontmatter.order_display_categories_beverages

    return (
      <div id={"menu-list-beverages"}>
        <Categories
          dataCategories={currentCategories}
          dataProducts={dataBoissons}
          dataSettings={dataSettings}
          arrayOrderCategories={arrayOrderCategoriesBeverages}/>
      </div>
    )
  }
}