import React from "react";

/**
 * Composant permettant d'afficher les prix et quantité d'un produit de type plat
 * @returns {JSX.Element}
 * @constructor
 */
export default class ProductPricequantityDishe extends React.Component {
  render() {
    const { priceQuantity, dataSettings } = this.props
    const mainSettingsCarte = dataSettings.edges.find(setting => setting.node.name.includes("parametres-page-carte")).node

    let disheData = {
      priceDisheAndBeverage : mainSettingsCarte.childMarkdownRemark.frontmatter.price_dishe_beverage,
      priceDisheAndBeverageAndCoffee : mainSettingsCarte.childMarkdownRemark.frontmatter.price_dishe_beverage_coffee,
      listBeveragesAllowed: mainSettingsCarte.childMarkdownRemark.frontmatter.list_beverages_allowed
    }

    let priceAndBeverage = Number.parseFloat(priceQuantity[0].prix.replace(",", ".")) + Number.parseFloat(disheData.priceDisheAndBeverage);
    let priceAndBeverageAndCoffee = priceAndBeverage + Number.parseFloat(disheData.priceDisheAndBeverageAndCoffee);
    return(
      <div className={"product-price-dishe-container"}>
        <div className={"single"}>
          <div className={"price"}>{Number.parseFloat(priceQuantity[0].prix.replace(",", ".")).toFixed(2)}&nbsp;€</div>
        </div>
        <div className={"single-and-beverage"}>
          <div className={"title"}>+ Boissons</div>
          <div className={"price"}>{Number.parseFloat(priceAndBeverage).toFixed(2)}&nbsp;€</div>
        </div>
        <div className={"single-and-beverage-and-coffee"}>
          <div className={"title"}>+&nbsp;Café Gourmand</div>
          <div className={"price"}>{Number.parseFloat(priceAndBeverageAndCoffee).toFixed(2)}&nbsp;€</div>
        </div>
      </div>
    )
  }
}
